import React from 'react';
import Swiper from 'swiper';
import 'swiper/css';
import Image from '../img/image.png';
// import swiperjs from '../js/swiper.js';


const swiper = new Swiper('.swiper', {
    // Optional parameters
    direction: 'vertical',
    loop: true,
  
    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
    },
  
    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  
    // And if we need scrollbar
    scrollbar: {
      el: '.swiper-scrollbar',
    },
  });

const Contact = () => {
    
    return (
    < div className = "grand_wrapper contact_wrapper" >
      <p>test building now</p>
            <div className="swiper">
                <div className="swiper-wrapper">
                    <div className="swiper-slide"><img src={Image} alt="" /></div>
                    <div className="swiper-slide"><img src={Image} alt="" /></div>
                    <div className="swiper-slide"><img src={Image} alt="" /></div>
                </div>
            </div>
    </div >
    );
    
};

export default Contact;
